






































import { Component, Prop, Vue } from "vue-property-decorator";
import { CompanyUser } from "@/store/models/user";

@Component({
  inject: ["$validator"]
})
export default class Registrationone extends Vue {
  @Prop({ default: {} as CompanyUser })
  user!: CompanyUser;
}
