


























import { Component, Prop, Vue } from "vue-property-decorator";
import Stepone from "@/views/Authscreen/Companyreg/Steps/Stepone.vue";
import Steptwo from "@/views/Authscreen/Companyreg/Steps/Steptwo.vue";
import Stepthree from "@/views/Authscreen/Companyreg/Steps/Stepthree.vue";
import { CompanyUser } from "@/store/models/user";
import { UserStore } from "@/store/modules";

@Component({
  components: {
    Stepone,
    Steptwo,
    Stepthree
  },
  metaInfo: {
    title: "Company Registration",
    meta: [
      {
        name: "description",
        content: "Company Registration",
        vmid: "registration"
      }
    ]
  }
})
export default class Registrationinit extends Vue {
  active: number = 0;
  user: CompanyUser = {} as CompanyUser;

  registrationCertificate: File[] = [] as File[];
  licenseCertificate: File[] = [] as File[];
  trainingCertificate: File[] = [] as File[];
  othersCertificate: File[] = [] as File[];

  async nextstep() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      if (this.active++ > 2) this.active = 0;
      else if (this.active >= 3) {
        this.user.username = this.user.email;

        let company = new FormData();
        for (let key in this.user as any) {
          company.append(key, (this.user as any)[key]);
        }
        company.append("latitude", "");
        company.append("longitude", "");

        for (let file of this.registrationCertificate) {
          company.append("registrationcertificate", file);
        }
        for (let file of this.licenseCertificate) {
          company.append("license", file);
        }
        for (let file of this.trainingCertificate) {
          company.append("trainingcertificate", file);
        }
        for (let file of this.othersCertificate) {
          company.append("otherdocument", file);
        }
        let a = await UserStore.registerCompany(company);
        if (a.id !== undefined && a.id !== null && a.id !== 0 && a.id !== "") {
          this.$notify.success({
            title: this.$t("registrationSuccessfulTitle").toString(),
            message: this.$t("registrationSuccessfulMessage").toString()
          });
          this.user = {} as CompanyUser;
          this.$router.push({ name: "Home" }).catch(error => {});
        } else {
          this.$notify.error(this.$t("registrationFailedMessage").toString());
        }
      }
    }
  }

  handleAfterUploadRegistration(file: any) {
    this.registrationCertificate = [];
    this.registrationCertificate.push(file);
  }

  handleAfterUploadLicense(file: any) {
    this.licenseCertificate = [];
    this.licenseCertificate.push(file);
  }

  handleAfterUploadTraining(file: any) {
    this.trainingCertificate = [];
    this.trainingCertificate.push(file);
  }

  handleAfterUploadOthers(file: any) {
    this.othersCertificate = [];
    this.othersCertificate.push(file);
  }
}
