






























































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { CompanyUser } from "@/store/models/user";
import { MetaStore, JWTStore } from "@/store/modules";

@Component({
  inject: ["$validator"]
})
export default class Registrationtwo extends Vue {
  @Prop({ default: {} })
  user!: any;
  option: any = "";
  organization = [
    {
      label: "Government",
      value: "Government",
      description: ""
    },
    { label: "Private", value: "Private", description: "" },
    { label: "NGO/INGO", value: "NGO/INGO", description: "" },
    {
      label: "Research Center",
      value: "Research Center",
      description: ""
    },
    { label: "Group/Cooperative", value: "Cooperative", description: "" },
    { label: "Others", value: "Others", description: "" }
  ];
  labelPosition: string = "top";
  orgdetails = {
    name: "",
    region: "",
    type: "",
    password: ""
  };

  get provinces() {
    return MetaStore.Province;
  }

  get districts() {
    let district = MetaStore.District;
    if (this.user.provinceid) {
      district = district.filter(x => x.provinceid == this.user.provinceid);
    } else {
      district = [];
    }
    return district;
  }

  get palikas() {
    let palika = MetaStore.Palika;
    if (this.user.districtid) {
      palika = palika.filter(x => x.districtid == this.user.districtid);
    } else {
      palika = [];
    }
    return palika;
  }

  get banklist() {
    return MetaStore.BankList;
  }

  created() {
    MetaStore.getAllProvince();
    MetaStore.getAllDistrict();
    MetaStore.getAllPalika();
    MetaStore.getAllBankList();
  }
}
